import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Head from '../../components/head';
import BasicLayout from '../../layouts/basic_layout';
import styles from '../index.module.scss';
import stylesBejelentkezes from '../bejelentkezes.module.scss';
import BreadcrumbsLayout from '../../layouts/breadcrumbs_layout';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { useMutation } from '@apollo/client';
import { ACCEPT_USER_PASSWORD_RESET_VIA_UNIDENTIFIED_ACTOR } from '../../apollo/mutations/user';
// import { navigate } from '@reach/router';
import { withSnackbar } from 'notistack';
import { navigate } from 'gatsby';
YupPassword(yup);

const PasswordResetAccept = ({ location, enqueueSnackbar }) => {
  const [form, setForm] = useState({
    password: '',
    passwordOnceAgain: '',
  });
  const [error, setError] = useState(null);

  const params = new URLSearchParams(location.search);
  const parameter1 = params.get('resetKey');
  const parameter2 = params.get('code');
  const parameter3 = params.get('dedicated');

  const [changePassword] = useMutation(
    ACCEPT_USER_PASSWORD_RESET_VIA_UNIDENTIFIED_ACTOR,
    {
      onCompleted() {
        enqueueSnackbar('Jelszó változtatás sikeres!', {
          variant: 'success',
        });

        navigate('/bejelentkezes');
      },
      onError(error) {
        enqueueSnackbar('Jelszó változtatás sikertelen!', {
          variant: 'error',
        });
      },
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY' });
        cache.gc();
      },
    }
  );

  const onFormChange = ({ target }) => {
    const { name, value } = target;

    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const schema = yup.object({
      password: yup
        .string()
        .minSymbols(
          1,
          'A jelszónak legalább 1 speciális karaktert tartalmaznia kell'
        )
        .minNumbers(1, 'A jelszónak legalább egy számot tartalmaznia kell')
        .minUppercase(1, 'A jelszónak legalább egy nagybetűt tartalmaznia kell')
        .minLowercase(1, 'A jelszónak legalább egy kisbetűt tartalmaznia kell')
        .min(
          8,
          'A jelszónak legalább 8 karaktert kell tartalmaznia a következőekkel: nagybetű, kisbetű, szám és speciális karakter'
        )
        .required('Kötelező mező'),
      passwordOnceAgain: yup
        .string()
        .oneOf([yup.ref('password'), null], 'A jelszavaknak egyezniük kell')
        .required('Kötelező mező'),
    });

    try {
      schema.validateSync(form, {
        abortEarly: false,
      });

      setError(null);

      changePassword({
        variables: {
          data: {
            args_0: parameter1,
            args_1: parameter2,
            args_2: parameter3,
            args_3: form.password,
          },
        },
      });
    } catch (e) {
      setError(
        e.inner.reduce(
          (a, x) => ({
            ...a,
            [x.path]: x.message,
          }),
          {}
        )
      );
    }
  };

  return (
    <BasicLayout>
      <Head title="Jelszó csere" />
      <BreadcrumbsLayout>
        <div className={styles.textContentContainer}>
          <h3>Jelszó csere</h3>

          <div className={stylesBejelentkezes.container}>
            <label htmlFor="password">Jelszó</label>
            <TextField
              id="password"
              type="password"
              name="password"
              value={form?.password}
              onChange={onFormChange}
              variant="outlined"
              fullWidth
            />
            {error?.password && (
              <div style={{ color: 'red' }}>{error?.password}</div>
            )}
            <label htmlFor="passwordOnceAgain">Jelszó mégegyszer</label>
            <TextField
              id="passwordOnceAgain"
              type="password"
              name="passwordOnceAgain"
              value={form?.passwordOnceAgain}
              onChange={onFormChange}
              variant="outlined"
              fullWidth
            />
            {error?.passwordOnceAgain && (
              <div style={{ color: 'red' }}>{error?.passwordOnceAgain}</div>
            )}
            <div className={stylesBejelentkezes.submitButtonContainer}>
              <Button
                className={stylesBejelentkezes.submitButton}
                onClick={handleSubmit}
              >
                Küldés
              </Button>
            </div>
          </div>
        </div>
      </BreadcrumbsLayout>
    </BasicLayout>
  );
};

export default withSnackbar(PasswordResetAccept);
